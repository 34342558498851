import { NewsItem } from "../domain/news-item";
import { Photo } from "../domain/photo";

const createNewsItem = (title: string,
                        date: Date,
                        content: string,
                        picture?: Photo): NewsItem => {
  return new NewsItem(title, date, content, picture);
};

export const newsItems = [
  createNewsItem("Dokter Mulder stopt in de praktijk", new Date("2025-02-13"),
      `Beste patiënten,
      \n Met een dubbel gevoel laat ik u weten dat ik per 1 maart stop als huisarts in deze praktijk.
      De afgelopen 5,5 jaar heb ik met veel liefde en plezier gewerkt in de praktijk, maar ik heb besloten te gaan werken in een andere praktijk, dichterbij huis.
      \n In mijn jaren hier heb ik met patiënten gelachen, hun verdriet gevoeld en voor uitdagingen gestaan.
      Ik heb zoveel mogelijk gezocht naar oplossingen, waarbij uw gezondheid altijd voorop stond.
      \n Ik dank u voor het vertrouwen in mij als (uw) huisarts en wens u het beste toe in goede gezondheid.
      \n&nbsp;
      \n Willemijn Mulder,
      \n Huisarts`),
  createNewsItem("Goede voeding", new Date("2022-05-02"),
      `Gezond eten is belangrijk, hiermee zorg je dat je lichaam goed functioneert en fit wordt of blijft.
      Daarnaast vermindert goede voeding het risico op bijvoorbeeld hart- en vaatziekten, diabetes en kanker.
      Met het oog op de zomer willen veel mensen graag in het voorjaar afvallen of bewuster gaan eten.
      Voeding is hier ook een belangrijk onderdeel van.
      \n Gezonde voeding zorgt ervoor dat je van alle voedingsstoffen voldoende binnen krijgt.
      Hieronder staan een aantal tips.
      Wilt u meer weten over gezonde voeding, klik dan op onderstaande link:
      [https://www.thuisarts.nl/gezond-leven/ik-wil-gezond-eten](https://www.thuisarts.nl/gezond-leven/ik-wil-gezond-eten).
      \n- Eet voldoende groente en fruit
      \n- Kies voor volkoren producten
      \n- Eet elke week peulvruchten
      \n- Kies voor gezonde vetten
      \n- Beperk zoutinname
      \n- Kies voor drinken zonder suiker en wees matig met alcohol`),
  createNewsItem("Prikpost Dorst weer geopend", new Date("2021-01-16"),
      `De prikpost in onze praktijk in Dorst is weer geopend.
       U kunt voor bloed prikken terecht op de woensdagen van 08.00 tot 11.00 uur.
       In verband met corona is dit uitsluitend op afspraak mogelijk.
       Dit kan bij Star-shl via [https://www.star-shl.nl/patient/afspraak-maken/](https://www.star-shl.nl/patient/afspraak-maken/).
       \n Mocht u hulp nodig hebben bij het maken van een afspraak kunt u contact opnemen met de praktijk,
       de assistentes willen u hier graag bij helpen.`)
]
    .filter(newsItem => newsItem.date <= new Date())
    .sort((a, b) => b.date.getTime() - a.date.getTime());